<template>
	<div>
		<el-header style="font-size: 12px;height: 50px;">
			<el-row :gutter="20">
				<el-col :span="20">
					<el-button @click="dialogVisible = true" type="primary" icon="el-icon-circle-plus-outline">添加
					</el-button>
				</el-col>
				<el-col :span="4">
					<el-input style="width: 200px;" placeholder="请输入内容" v-model="keywords">
						<i slot="prefix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</el-col>
			</el-row>
		</el-header>
		<el-table v-loading="loading" :data="tableData.slice((currentPage-1)*pageSize, currentPage*pageSize)"
			style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column prop="id" align="center" label="ID" width="80">
			</el-table-column>
			<el-table-column prop="name" align="center" label="名称" width="240">
			</el-table-column>
			<el-table-column prop="description" label="分类描述">
			</el-table-column>
			<el-table-column label="操作" width="180">
				<template slot-scope="scope">
					<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
			:page-sizes="[5, 10, 20, 50]" :page-size="pageSize" :total="tableData.length"
			layout="total, sizes, prev, pager, next, jumper">
		</el-pagination>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<el-form ref="form" :model="form" label-width="50px">
				<el-form-item label="名称">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="描述">
					<el-input type="textarea" v-model="form.description"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="queding">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				currentPage: 1,
				pageSize: 10,
				tableData: [],
				keywords: '',
				dialogVisible: false,
				form: {
					name: '',
					description: ''
				},
				fullscreenLoading: false,
				loading: false
			}
		},
		created() {
			this.getlist();
			console.log('ll')
		},
		methods: {
			getlist() {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/addGoodsCategory.aspx'
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 500)
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.tableData = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.currentPage = val;
			},
			queding() {
				if (this.form.name == "") {
					this.$message({
						type: 'info',
						message: `请输入分类名称！`
					});
					return;
				}
				if (this.form.description == "") {
					this.$message({
						type: 'info',
						message: `请输入分类描述！`
					});
					return;
				}
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData = new FormData()
				formData.append('name', this.form.name)
				formData.append('token', this.$Cookies.get("vue_elm_token"))
				formData.append('description', this.form.description)
				const url = 'http://www.vssite.com/api/addGoodsCategory.aspx'
				axios.post(url, formData).then(data => {
					loading.close();
					if (data.data.ret != 0) {
						this.$message({
							type: 'warning',
							message: data.data.msg
						});
					} else {
						this.$message({
							type: 'success',
							message: "添加成功！"
						});
						this.dialogVisible = false;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			handleClose() {
				this.dialogVisible = false;
				console.log("index");
			},
			handleEdit(index, row) {
				this.$alert('这是一段内容', '标题名称', {
					confirmButtonText: '确定',
					callback: action => {
						this.$message({
							type: 'info',
							message: `action: ${ action }`
						});
					}
				});
				console.log(index, row);
			},
			handleDelete(index, row) {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					const formData = new FormData()
					formData.append('id', row.id)
					formData.append('act', "delete")
					formData.append('token', this.$Cookies.get("vue_elm_token"))
					const url = 'http://www.vssite.com/api/addGoodsCategory.aspx'
					axios.post(url, formData).then(data => {
						if (data.data.ret != 0) {
							this.$message({
								type: 'warning',
								message: data.data.msg
							});
						} else {
							this.$message({
								type: 'success',
								message: "删除成功！"
							});
						}
						setTimeout(() => {
							this.loading = false;
							this.getlist();
						}, 1000)
						console.log('data', data)
					}).catch(response => {
						console.log(response)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				console.log(index, row);
			},
			load(tree, treeNode, resolve) {
				setTimeout(() => {
					resolve([{
						id: 31,
						date: '2016-05-01',
						name: '王小虎',
						address: '上海市普陀区金沙江路 1519 弄'
					}, {
						id: 32,
						date: '2016-05-01',
						name: '王小虎',
						address: '上海市普陀区金沙江路 1519 弄'
					}])
				}, 1000)
			}
		},
	}
</script>

<style lang="less" scoped>
	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
